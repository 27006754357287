import React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import Logo from "../../assets/images/global/logo.svg";
import FacebookIcon from "../../assets/images/footer/facebook.svg";
import LinkedinIcon from "../../assets/images/footer/linkedin.svg";
import YoutubeIcon from "../../assets/images/footer/youtube.svg";
import InstagramIcon from "../../assets/images/footer/instagram.svg";
import { TABS } from "../headers/header-about";
import Clutch from "../clutch/clutch.js";
const content = [
  {
    town: "Baar",
    street: "Rathausstrasse 14",
    zip: "6340 Baar",
  },
  {
    town: "Novi Sad",
    street: "Novosadskog sajma 2",
    zip: "21000 Novi Sad",
  },
  {
    town: "Berlin",
    street: "Technopark 1 Strasse",
    zip: "10115 Berlin",
  },
  {
    town: "Toronto",
    street: "90 Queens Wharf Road",
    zip: "Toronto, Ontario",
  },
];

const socials = [
  {
    url: "https://www.instagram.com/ewa.soft/",
    icon: <InstagramIcon />,
    label: "Instagram icon",
  },
  {
    url: "https://www.linkedin.com/company/ewasoft-doo",
    icon: <LinkedinIcon />,
    label: "Linkedin icon",
  },
  {
    url: "https://www.youtube.com/channel/UCt-dL0t6ljb5Lu2lf7CucYA",
    icon: <YoutubeIcon />,
    label: "Youtube icon",
  },
  {
    url: "https://www.facebook.com/ewa.soft/",
    icon: <FacebookIcon />,
    label: "Facebook icon",
  },
];

const Footer = () => {
  const { t } = useTranslation();
  const current = new Date();
  const date = `${current.getFullYear()}`;

  return (
    <footer className="m-footer">
      <Clutch />
      <div className="_wr">
        <div className="m-footer__content">
          <div className="m-footer__socials">
            <div className="m-footer__socials--logo">
              <Logo />
            </div>
            <div className="m-footer__socials--text">
              <p>{t("footer.slogan")}</p>
            </div>
            <div className="m-footer__socials--icons">
              {socials.map(({ url, icon, label }, i) => (
                <a
                  href={url}
                  key={i}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={label}
                  title={label}
                >
                  {icon}
                </a>
              ))}
            </div>
          </div>
          <div className="m-footer__locations">
            <div className="m-footer__locations--container">
              {content.map(({ town, street, zip }, i) => (
                <div className="m-footer__item" key={i}>
                  <div>
                    <p className="m-footer__item--title">{town}</p>
                    <p className="m-footer__item--text">{street}</p>
                    <p className="m-footer__item--text">{zip}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="m-cardLinks">
              <Link
                to="/about-us/#location"
                onClick={() => {
                  window.scrollTo(0, 0);
                  // window.location.href = "/about-us/#location";
                }}
              >
                {t("footer.allLocations")}
              </Link>
              <span className="a-arrow -long"></span>
            </div>
          </div>
        </div>
        <div className="m-footer__bottom">
          <p className="m-footer__bottom--text">
            &copy;{t("footer.copy")} {date} | <strong>ewasoft</strong> |{" "}
            {t("footer.rights")}
          </p>
          <div
            className="clutch-widget"
            data-url="https://widget.clutch.co"
            data-widget-type="14"
            data-height="50"
            data-nofollow="true"
            data-expandifr="true"
            data-clutchcompany-id="1932060"
          ></div>
          {/* <a className="m-footer__bottom--text" href="/#">
          {t('footer.terms')}
        </a>
        <a className="m-footer__bottom--text" href="/#">
          {t('footer.privacy')}
        </a> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
