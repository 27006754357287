import React from "react";
import { Script } from "gatsby";

const Clutch = () => {
  return (
    <>
      <script src="https://widget.clutch.co/static/js/widget.js"> {""}</script>
    </>
  );
};

export default Clutch;
