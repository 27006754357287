import React, { useContext } from "react";
import Navigation from "../navigation/navigation";
import Footer from "../footer/footer";
import Contact from "../contact/contact";
import withModalContext, { ModalContext } from "../../context/ModalContext";
import { graphql } from "gatsby";

const Layout = ({ children }) => {
  const { openModal, setOpenModal } = useContext(ModalContext);

  return (
    <div>
      <Navigation openModal={openModal} setOpenModal={setOpenModal} />
      {openModal && <Contact setOpenModal={setOpenModal} />}
      {children}
      <Footer />
    </div>
  );
};

export default withModalContext(Layout);
